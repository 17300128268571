@import url("https://fonts.googleapis.com/css?family=Ubuntu&display=swap");
@import "pacc/_settings.scss";
@import "../node_modules/foundation-sites/scss/foundation.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../vendor/tracy/tracy/src/Tracy/Dumper/assets/dumper-light.css";
@import "../vendor/tracy/tracy/src/Tracy/assets/toggle.css";
@import "pacc/_pacc.scss";

// Global styles
@include pacc-global-styles;
@include pacc-typography-base;
@include pacc-typography-helpers;
@include foundation-form-text;
@include foundation-form-label;

$logo-size: 3rem;

body {
    bottom: 0;
    position: absolute;
    left: 0;
    overflow: auto;
    right: 0;
    top: 0;
}

button {
    $button-margin: 0 0 $global-margin 0;
    @include button($background: $success-color, $background-hover: scale-color($success-color, $lightness: -15%), $color: $primary-color);

    &:focus, &:hover {
        color: $primary-color;
    }
}

.flashes {
    margin: -1.3rem auto 0 auto;
    max-width: 87vw;
    position: relative;
    text-align: left;
    width: 40em;
    z-index: 5;

    &:has(div) {
        padding-bottom: 1rem;
    }

    > div {
        @include callout();

        margin-bottom: 1rem;
        overflow-wrap: break-word;
        padding: map-get($callout-sizes, tiny);
        transition: opacity 1s;

        &.error {
            @include callout-style($color: get-color(alert));
        }
        &.info {
            @include callout-style($color: $medium-gray);
        }
        &.success {
            @include callout-style($color: get-color(success));
        }

        i.close-button {
            @include close-button();

            right: -zf-get-size-val($closebutton-offset-horizontal, small);
            top: -zf-get-size-val($closebutton-offset-vertical, small);
            font-size: -zf-get-size-val($closebutton-size, small);
            line-height: inherit;
        }
    }
}

h1 {
    color: $primary-color;
    font-size: calc($logo-size / 1.5);
    margin-left: calc($logo-size);
    position: relative;

    &::selection {
        background: none;
    }

    &:before {
        background: url("../images/logo-pacc.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 50%;
        content: '';
        display: block;
        height: $logo-size;
        position: absolute;
        top: 50%;
        left: calc($logo-size * -1);
        margin-left: 1px;
        margin-top: calc(calc($logo-size / 2) * -1);
        width: $logo-size;
    }
}

footer {
    margin-top: 0.5rem;
    min-height: 2rem;
    padding: 0 $global-padding;
    text-align: center;

    a {
        color: $secondary-color;
        padding: 0 0.5rem 0 1.5rem;

        &:before {
            @extend %fa-icon;
            @extend .fab;

            margin-left: -1rem;

        }
        &:nth-of-type(1) {
            &:before {
                content: fa-content($fa-var-gitlab);
            }
        }
        &:nth-of-type(2) {
            &:before {
                content: fa-content($fa-var-php);
            }
        }

        &:focus, &:hover {
            color: $black;
        }
    }
}

header {
    padding: $global-padding;
    position: relative;

    form {
        @include xy-grid;

        opacity: $button-opacity-disabled;
        position: absolute;
        right: calc($global-padding + .625rem);
        top: calc($logo-size / 2);
        width: 14em;

        &:hover, &:focus-within {
            opacity: 1;
        }
        button {
            @include xy-cell(5);

            margin: 0 0 0 $global-padding;
        }
        label {
            @extend .is-hidden;
        }
        select {
            @include form-select;
            @include xy-cell(auto);

            margin: 0;
        }
    }
}

main {
    padding: 0 $global-padding;

    form {
        @include xy-grid;
        @include flex-align($x: center);

        min-height: 24rem;
        height: 80vh;

        fieldset {
            @include border-none;
            @include xy-cell(6);

            height: calc(100% - 4rem);
            position: relative;

            legend {
                @extend .is-hidden;
            }

            .cm-container,
            .tracy-dump {
                @include form-element;
                @extend textarea;

                bottom: 0;
                left: 0;
                padding: 0;
                position: absolute;
                right: 0;
                top:0;

                /* fix border radius z-index issue */
                -webkit-transform: translate3d(0, 0, 0);

                &:focus-within {
                    box-shadow: $input-shadow-focus;

                    @if has-value($input-transition) {
                        transition: $input-transition;
                    }
                }
            }

            textarea,
            .cm-container {
                border-color: $light-gray;
                height: 100%;
                z-index: 10;

                &[data-invalid='true'] {
                    @include form-input-error;
                }
                &:focus, &:focus-within {
                    border-color: rgba($primary-color, 0.4);
                }
                &:hover {
                    border-color: rgba($primary-color, 0.6);
                }
            }

            .cm-content {
                &[aria-readonly='true'] {
                    background-color: $input-background-disabled;
                    cursor: $input-cursor-disabled;

                    ::selection {
                        background: rgba($primary-color, 0.1) !important;
                    }
                }
            }

            div[data-preferred-view="code"] {
                .tracy-dump {
                    display: none;
                }
            }
            div[data-preferred-view="debug"] {
                .tracy-dump {
                    display: block;
                    margin: 0;
                    padding: 0 0 0 1em;
                    z-index: 20;
                }
            }
        }

        > div {
            @include xy-cell(12);

            height: 4rem;
            padding-top: $global-padding;
            position: relative;
            text-align: center;

            button {
                margin: 0 calc(rem-calc(map-get($grid-margin-gutters, small) * 0.5) - 2px);
                width: 6.5rem;

                &:nth-of-type(3) {
                    @include button-hollow;
                    @include button-hollow-style($color: $success-color);

                    position: absolute;
                }
            }
        }
    }
}