/**
 * This file is part of the Tracy (https://tracy.nette.org)
 */

.tracy-collapsed {
	display: none;
}

.tracy-toggle.tracy-collapsed {
	display: inline;
}

.tracy-toggle {
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
}

.tracy-toggle:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	line-height: 0;
	border-top: .6ex solid;
	border-right: .6ex solid transparent;
	border-left: .6ex solid transparent;
	transform: scale(1, 1.5);
	margin: 0 .2ex 0 .7ex;
	transition: .1s transform;
	opacity: .5;
}

.tracy-toggle.tracy-collapsed:after {
	transform: rotate(-90deg) scale(1, 1.5) translate(.1ex, 0);
}
